import {
  useProductList,
  useProductListItems
} from '@jetshop/core/hooks/ProductList';
import t from '@jetshop/intl';
import { styled } from 'linaria/react';
import React, { useEffect } from 'react';
import { theme } from '../Theme';
import { ReactComponent as ShareIcon } from '../../svg/Share.svg';
import { useLocation } from 'react-router';

const ShareButton = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  height: 40px;
  line-height: 11px;
  text-decoration: none;
  cursor: pointer;
  background: #fff;
  font-family: ${theme.fonts.primary};
  font-size: 11px;
  font-weight: 600;
  border: 1px solid black;
  transition: all 0.3s ease-in-out;

  &:hover,
  &:active {
    background: ${theme.colors.black};
    color: ${theme.colors.white};
  }

  svg {
    height: 15px;
    margin-right: 6px;
  }
`;

export const Share = ({ drawer }) => {
  const { products } = useProductListItems();
  const { add } = useProductList();
  const { search } = useLocation();

  useEffect(() => {
    if (search?.includes('?products=')) {
      const decUrl = decodeURI(search);
      const commaseparatedProducts = decUrl?.match(/\[(.*)\]/).pop();
      const sharedProducts = commaseparatedProducts?.split(',');

      //checks if product is in fav-list
      sharedProducts?.forEach(sProd => {
        const existingProduct = products?.find(
          prod => prod.articleNumber === String(sProd)
        );

        if (!existingProduct) {
          add(sProd, {
            variantArticleNumber: null,
            productName: ''
          });
        }
      });
      drawer?.showTarget();
    }
  }, [search, add, drawer, products]); // Lägg till de saknade beroendena här

  // SHARE
  const handleShareTapped = () => {
    // creates a string from articlenumber list
    const sharedAricleNumbersString = products
      .map(prod => prod?.articleNumber)
      .join(',');

    const baseUrl = window?.location?.origin;
    const shareUrl = encodeURI(
      `${baseUrl}/favourites?products=[${sharedAricleNumbersString}]`
    );
    // start share
    if (navigator?.share) {
      navigator
        .share(
          {
            title: 'Ønskeliste - Ferner Jacobsen',
            text: 'Klikk på lenken under for å se hva jeg ønsker meg',
            url: shareUrl
          },
          {
            // change this configurations to hide specific unnecessary icons
            copy: true,
            email: true,
            print: true,
            sms: true,
            messenger: false,
            facebook: true,
            whatsapp: true,
            twitter: true,
            linkedin: false,
            telegram: false,
            skype: false,
            pinterest: false,
            language: 'en' // specify the default language
          }
        )
        .then(_ => console.log('Sharing completed'))
        .catch(error => console.log("Error while sharing :'(\n", error));
    }
  };

  if (products?.length < 1) {
    return null;
  }

  return (
    <>
      <ShareButton
        className="share-trigger"
        onClick={() => {
          handleShareTapped();
        }}
      >
        <ShareIcon />
        {t('Share list')}
      </ShareButton>
    </>
  );
};
